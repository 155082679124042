import React from 'react';
import './Waves3.css';
import { useRef, useEffect } from 'react';
// import logo from './img/whaleSafev2-01.png';
// import logo from './img/whaleSafev4_6.png';
import logo from './img/whaleRedSmallWHT.png';
// import {ReactComponent as WaveImage} from './img/wave.svg';
// import waveImage from './img/waves_image6-removebg-preview.png';
// import waveImage from './img/oie_trans (1).gif';
import waveImage from './img/waves_drawing.png';
// import moonImage from './img/moon_image1-removebg-preview.png';
import moonImage from './img/moon.png';

const Waves = (props) =>{

    const presale = (event) => {
      window.location = 'https://presale.whalesafecrypto.com'
    }

    return (
        <div className='waves'>
			<div class='stars'></div>
			<div class='stars2'></div>
			<div class='stars3'></div>
	        <img className='logo-main' src={ logo }></img>
        	<p className='message-main'>Revolutionary business, payments, and dex platform. Groundbreaking, first-ever anti-whale tokenomic features. Doxxed and highly qualified team.</p>
            <div className='cta-btn-container'>
                <button className='cta-btn' onClick= { presale }>Presale</button>
                {/*<button className='cta-btn'>Chart</button>*/}
            </div>
        	<div className='wave-img-container'>
        		{/*<WaveImage className='wave-img-svg-original' />*/}
                <img className='wave-img' src={ waveImage }></img>
                {/*<iframe className='wave-img' src="https://giphy.com/embed/FgJ6FbfJGwztK"></iframe>*/}
        	</div>
            <img className='moon-img' src={ moonImage }></img>
        </div>
    );

}

export default Waves;