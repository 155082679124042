import React from 'react';
import './EverythingElse1.css'
import BscScanLogo from './img/bscscan.png';
import CertikLogo from './img/certik.svg';
import CoinGeckoLogo from './img/coingecko.png';
import CoinMarketCapLogo from './img/coinmarketcap.png';
import NomicsLogo from './img/nomics.png';
import TwitterLogo from './img/twitter-small.png';
import RedditLogo from './img/reddit.png';
import FacebookLogo from './img/facebook2.png';
import LinkedinLogo from './img/linkedin7.png';
import GitHubLogo from './img/github3.png';
import TelegramLogo from './img/telegram.png';
import InstagramLogo from './img/instagram2.png';

// import TwitterLogo from './img/twitter_blue2.png';

const EverythingElse1 = (props) =>{

    return (
        <div className='everything-else1'>
          <a href="https://bscscan.com/token/0xc02aca66b969c9Db976C5D8535eCD5c13558f5b8"><img className='logo-img' src={ BscScanLogo }/></a>
          {/*<img className='logo-img' src={ CertikLogo }/>*/}
          {/*<img className='logo-img' src={ CoinGeckoLogo }/>*/}
          {/*<a href="https://coinmarketcap.com"><img className='logo-img' src={ CoinMarketCapLogo }/></a>*/}
          {/*<img className='logo-img' src={ NomicsLogo }/>*/}
          <a href="https://www.instagram.com/whalesafecrypto/"><img className='logo-img' src={ InstagramLogo }/></a>
          <a href="https://twitter.com/whalesafecrypto"><img className='logo-img' src={ TwitterLogo }/></a>
          <a href="https://www.reddit.com/r/whalesafecrypto"><img className='logo-img' src={ RedditLogo }/></a>
          <a href="https://t.me/WhaleSafe"><img className='logo-img' src={ TelegramLogo }/></a>
          <a href="https://www.facebook.com/WhaleSafe-105073001845522"><img className='logo-img' src={ FacebookLogo }/></a>
          {/*<a href="https://www.linkedin.com/"><img className='logo-img' src={ LinkedinLogo }/></a>*/}
          <a href="https://github.com/WhaleSafe"><img className='logo-img' src={ GitHubLogo }/></a>
        </div>
    );

}

export default EverythingElse1;