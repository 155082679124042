import React from 'react';
import './EverythingElse2__LitePaperModule.css';


const EverythingElse2__LitePaperModule = (props) =>{

    return (
        <div className='everything-else2__litepaper-module'>
          <script src="https://cdnjs.cloudflare.com/ajax/libs/marked/0.3.2/marked.min.js"></script>

          <p className='everything-else2__litepaper-module-title'>
              { props.title }
          </p>
          <p className='everything-else2__litepaper-module-text' dangerouslySetInnerHTML={{__html: props.text}}>
          </p>
          <img className='everything-else2__litepaper-module-img' src={ props.imgSrc }/>
        </div>
    );

}

export default EverythingElse2__LitePaperModule;