import './App.css';
import Navbar from './components/Navbar';
// import Waves from './components/Waves';
import Waves3 from './components/Waves3';
import EverythingElse1 from './components/EverythingElse1';
import EverythingElse2 from './components/EverythingElse2';
// import TeamProfile from './components/TeamProfile';
import { useRef, useEffect } from 'react';
// import { ReactComponent as Logo } from './components/img/logo.svg';
import { Helmet } from 'react-helmet';

function App() {

  // document.title = 'WhaleSafe'

  return (
    <div className="App">
{/*      <Helmet>
        <title>WhaleSafe</title>
      </Helmet>*/}
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap" rel="stylesheet"/>
      <Navbar />
      <Waves3 />
      <EverythingElse1 />
      <EverythingElse2 />
      {/*<TeamProfile />*/}
    </div>
  );
}

export default App;





// class App extends React.Component {
//   render() {
//     return (
//       <div className="App">
//         <Helmet>
//           <meta charSet="utf-8" />
//           <title>My Title</title>
//           <link rel="canonical" href="mysite.com/example" />
//         </Helmet>
//         <link rel="preconnect" href="https://fonts.gstatic.com"/>
//         <link href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap" rel="stylesheet"/>
//         <Navbar />
//         <Waves3 />
//         <EverythingElse1 />
//         <EverythingElse2 />
//       </div>
//     );
//   }
// }

// export default App;