import React from 'react';
import './EverythingElse2.css';
import EverythingElse2__LitePaperModule from './EverythingElse2__LitePaperModule.js'
import baseballCap from './img/baseball-cap.png';
// import increase from './img/increase.png';
import increase from './img/bar-chart.png';
import beaker from './img/beaker.png';
import wallet from './img/wallet.png';
import marketing from './img/promotion.png';
// import partnership from './img/partnership.png';
import partnership from './img/deal (1).png';
import scissors from './img/scissor-tool.png';
import transparency from './img/study.png';
import alex from './img/alex.jpg';
import browser from './img/browser.png';

const EverythingElse2 = (props) =>{

    return (
        <div className='everything-else2'>
          <p className='everything-else2__content'>
	          <EverythingElse2__LitePaperModule
		          title='Fast Facts'
		          text="
			          <div style='text-align: left;'>
						<p>🔥 Doxxed, highly qualified team, ready to handle SafeMoon-level ambitions in a timely manner</p>
						<p>🔥 Revolution platform for businesses, payments, and decentralized trading</p>
			          	<p>🔥 <em>FIRST EVER</em> time-based anti-whale maximum ownership cap</p>
			          	<p>🔥 More than 21% locked in LP for 20 years</p>
			          	<p>🔥 1% liquidity fee</p>
			          	<p>🔥 0.5% reflection fee</p>
			          	<p>🔥 1 trillion total supply</p>
			          	<p>🔥 5% burnt, all other tokens in presale or dev/marketing wallets</p>
			          	<p>🔥 2% for dev wallet and 1% for marketing wallet, with serious restrictions on both</p>
			          	<p>🔥 Future partnerships and integrations to be announced</p>
			          </div>
			          "
		          imgSrc={ browser }/>
	          <EverythingElse2__LitePaperModule
	              title='Revolution Platform for Businesses, Payments, and Decentralized Trading'
	              text="MVP Release: August 23 (5 weeks out). We will outpace SafeMoon's release schedule. Users can search our directory for verified businesses like yours that take crypto. Pay someone by their user handle or contact info. Trade WhaleSafe and other tokens directly in the app. Non-custodial, so you can remain anonymous and own your tokens outright."
	              imgSrc={ wallet }/>
	          <EverythingElse2__LitePaperModule
		          title='0.5% Inital Max Ownership Cap'
		          text='Ownership is capped at 0.5% at launch, when protection is most needed. Accounts will not be able to snipe a huge portion on the cheap and dump after the initial pump.'
		          imgSrc={ baseballCap }/>
	          <EverythingElse2__LitePaperModule
	              title='FIRST EVER 0.5%-2.0% Time-Based Max Ownership Cap'
	              text='The max ownership cap grows each day, from 0.5% at launch, until it reaches 2.0% at day 75. This novel tokenomic mechanism ensures more equal access to entry points, daily buying events that will lead to price increases, and the impossibility that one account owns a perilous amount (think 26% of Dogecoin).'
	              imgSrc={ increase }/>
	          <EverythingElse2__LitePaperModule
	              title='Low Fees, Automatic LP Generation, Reflection'
	              text='WhaleSafe has a 1% fee that is sent to locked liquidity pools and a 0.5% reflection fee that is redistributed to the holders. WhaleSafe has total fees of 1.5% rather than the 10-13% plaguing other tokens.'
	              imgSrc={ beaker }/>
	          <EverythingElse2__LitePaperModule
	              title='Strategic Marketing'
	              text='We have been doing marketing even before the presale with influencers and massive Telegram channels. We are reaching out to as many cities and towns as possible via billboard advertising so that WhaleSafe current and future holders feel heard and seen. Whenver possible, our ads and social media posts will be educational and analytical. Follow us on Twitter and Reddit to be updated on our efforts.'
	              imgSrc={ marketing }/>
	          <EverythingElse2__LitePaperModule
	              title='Partnerships'
	              text="The team will leverage personal networks, participation in other crypto and technology products, and the token's popularity to make the token a method of payment and utility as widely as possible."
	              imgSrc={ partnership }/>
	          <EverythingElse2__LitePaperModule
	              title='Renounced Ownership'
	              text="Ownership will be renounced shortly after the presale has ended. The dev and marketing wallets will be locked for the first three days after the presale and will be subject to the anti-whale cap thereafter. We will have no special privileges."
	              imgSrc={ scissors }/>
	          <EverythingElse2__LitePaperModule
	              title='Transparency'
	              text="Our current team is doxxed, and our future team members will be publicly known. The combined percentage of coins in the dev (2%) and marketing (1%) wallets is small and explicitly stated, and these wallets and not exempt from the time-based maximum ownership cap or the liquidity tax. To dispel FUD, the dev and marketing wallets will be time-locked for the first three days after the presale. The contract will be renounced. We will have no special priviledges. We have additional plans in store, but we will not make the mistake that others tokens have and promise the world before delivering on their first promises."
	              imgSrc={ transparency }/>
	      </p>
        </div>
    );

}

export default EverythingElse2;